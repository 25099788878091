import { ExportationInlandTransportType } from "@sellernote/_shared/src/types/forwarding/adminBidCreate";

import { RadioGroupWithReactHookFormOption } from "../../components/RadioGroupWithReactHookForm";

const inlandTransportTypeRadioGroupOptionList: RadioGroupWithReactHookFormOption<ExportationInlandTransportType>[] =
  [
    {
      label: "독차",
      value: "sole",
    },
    {
      label: "합차",
      value: "consol",
    },
    {
      label: "해당 없음",
      value: "none",
    },
  ];

export { inlandTransportTypeRadioGroupOptionList };
