import { Box, Button, Typography } from "@mui/material";

import Modal from "../../../../../../../../../../../../components/Modal";

import { SendHBLEmailType } from "../../../../../_hooks/useSendHBLEmailRequest";

function SendHBLEmailModal({
  showsSendHBLEmailModal,
  setShowsSendHBLEmailModal,
  onHBLEmailSend,
  sendEmailType,
}: {
  showsSendHBLEmailModal: boolean;
  setShowsSendHBLEmailModal: (value: boolean) => void;
  onHBLEmailSend: () => void;
  sendEmailType: SendHBLEmailType;
}) {
  return (
    <Modal
      isOpened={showsSendHBLEmailModal}
      handleClose={() => setShowsSendHBLEmailModal(false)}
      modalBody={
        <>
          <Typography variant="h5">
            {sendEmailType === "check"
              ? "CHECK BL 발송을 진행하시겠습니까?"
              : "최종 BL 발송을 진행하시겠습니까?"}
          </Typography>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              gap: 4,
              marginTop: 4,
            }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={onHBLEmailSend}
            >
              예
            </Button>

            <Button
              variant="outlined"
              color="error"
              onClick={() => setShowsSendHBLEmailModal(false)}
            >
              아니오
            </Button>
          </Box>
        </>
      }
    ></Modal>
  );
}

export default SendHBLEmailModal;
