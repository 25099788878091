import { Controller, useFormContext } from "react-hook-form";
import { TextField, Typography } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

import { HBLInfo } from "@sellernote/_shared/src/types/forwarding/trello";

import CheckboxWithReactHookForm from "../../../../../../../../../../../components/CheckboxWithReactHookForm";

import Styled from "./index.styles";
function VesselAndTerms() {
  const {
    control,
    watch,
    formState: { errors },
  } = useFormContext<HBLInfo>();

  return (
    <>
      <Styled.vesselSection>
        <Styled.vesselColumn>
          <Styled.vesselItem>
            <div className="border-top"></div>

            <div className="title">Shipped on Board the Vessel DATE</div>

            <div className="content">
              <Controller
                control={control}
                name="ETD"
                rules={{ required: true }}
                render={({ field: { ref, ...field } }) => {
                  return (
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        {...field}
                        componentsProps={{
                          actionBar: { actions: ["clear"] },
                        }}
                        value={field.value}
                        inputFormat="MMM.dd.yyyy"
                        InputProps={{
                          style: {
                            fontSize: "9px",
                          },
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="standard"
                            onKeyDown={(event) => {
                              event.preventDefault();
                            }}
                            helperText={
                              errors.desiredAt?.type === "required" &&
                              "필수 입력 사항입니다."
                            }
                            error={errors.desiredAt?.type === "required"}
                            size="small"
                          />
                        )}
                      />
                    </LocalizationProvider>
                  );
                }}
              />
            </div>
          </Styled.vesselItem>
        </Styled.vesselColumn>

        <Styled.vesselColumn>
          <Styled.vesselItem>
            <div className="border-top"></div>
            <Styled.carrier>ACTING AS A CARRIER</Styled.carrier>
          </Styled.vesselItem>
          <Styled.carrierSignature>
            <div className="company-name">SELLER-NOTE Co.,Ltd.</div>
          </Styled.carrierSignature>
        </Styled.vesselColumn>
      </Styled.vesselSection>

      <Styled.termsSection>
        <Styled.termsBorder />
        <Styled.termsText>TERMS CONTINUED ON BACK HERE OF</Styled.termsText>

        <CheckboxWithReactHookForm
          name={"isAgree"}
          control={control}
          size="small"
          label={
            <Typography variant="body2" sx={{ fontSize: "10px" }}>
              {"이면약관 발행 여부"}
            </Typography>
          }
        />
      </Styled.termsSection>
    </>
  );
}

export default VesselAndTerms;
