import { useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { useQueryClient } from "react-query";

import TRELLO_BID_QUERY, {
  TRELLO_BID_QUERY_KEY_GEN,
} from "@sellernote/_shared/src/queries/forwarding/TRELLO_BID_QUERY";
import { HBLInfo } from "@sellernote/_shared/src/types/forwarding/trello";

import useSnackbar from "../../../../../../../../hooks/useSnackbar";

function useSaveHBLRequest({ shipmentId }: { shipmentId: number }) {
  const { mutate: saveHBL } = TRELLO_BID_QUERY.useSaveHBL({
    shipmentId,
  });

  const { mutate: tempSaveHBL } = TRELLO_BID_QUERY.useTempSaveHBL({
    shipmentId,
  });

  const { watch } = useFormContext<HBLInfo>();

  const { handleSnackbarOpen } = useSnackbar();

  const queryClient = useQueryClient();

  const formState = watch();

  const commonPayload = useMemo(() => {
    const {
      shipperName,
      shipperAddress,
      consigneeName,
      consigneeAddress,
      notifyParty,
      notifyPartyAddress,
      transportMode,
      origin,
      dest,
      pol,
      pod,
      containers,
      packingType,
      itemName,
      unknownClause,
      weight,
      cbm,
      unitSupply,
      blType,
      circulation,
      desiredAt,
      isAgree,
      partnerAddress,
      ETD,
    } = formState;

    return {
      shipperName,
      shipperAddress,
      consigneeName,
      consigneeAddress,
      notifyParty,
      notifyPartyAddress,
      transportMode,
      origin,
      dest,
      pol,
      pod,
      containers,
      packingType,
      itemName,
      unknownClause,
      weight,
      cbm,
      unitSupply,
      blType,
      circulation,
      desiredAt,
      isAgree,
      partnerAddress,
      ETD,
    };
  }, [formState]);

  const handleTemporarySaveClick = () => {
    tempSaveHBL(
      { ...commonPayload },
      {
        onSuccess: () => {
          handleSnackbarOpen("BL이 임시저장됐습니다.");
          queryClient.invalidateQueries(
            TRELLO_BID_QUERY_KEY_GEN.getHBLInfo({ shipmentId })
          );
        },

        onError: () => {
          handleSnackbarOpen("임시저장을 실패했습니다.", "error");
        },
      }
    );
  };

  const handleSaveClick = () => {
    saveHBL(
      { ...commonPayload },
      {
        onSuccess: () => {
          handleSnackbarOpen("BL이 저장됐습니다.");
          queryClient.invalidateQueries(
            TRELLO_BID_QUERY_KEY_GEN.getHBLInfo({ shipmentId })
          );
        },

        onError: () => {
          handleSnackbarOpen("저장을 실패했습니다.", "error");
        },
      }
    );
  };

  return {
    handleTemporarySaveClick,
    handleSaveClick,
  };
}

export default useSaveHBLRequest;
