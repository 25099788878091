/* eslint-disable no-useless-escape */
import { AlertColor } from "@mui/material";

import { GET_CARGO_MANIFEST_RES as CargoManifestInfoProps } from "@sellernote/_shared/src/api-interfaces/shipda-api/trello";

function hasMoreThanThreeDecimalPlaces(value: string | null) {
  if (!value) return false;

  return value.toString().split(".")[1]?.length > 3;
}

const validateClassification = (form: CargoManifestInfoProps) => {
  return (
    form.classification === "I" ||
    form.classification === "T" ||
    form.classification === "E" ||
    form.classification === "R"
  );
};

/** 각 컨테이너 포장 개수 총합이 전체 포장개수 총합과 맞는지 확인 */
const validatePackageCount = (form: CargoManifestInfoProps) => {
  if (!form.containers?.length) return true;

  const totalPackageCount = form.packageCount;

  const containerPackageCountSum = form.containers?.reduce((acc, cur) => {
    return acc + (cur.containerPackageCount ?? 0);
  }, 0);

  return totalPackageCount === containerPackageCountSum;
};

const validateBRN = (form: CargoManifestInfoProps) => {
  if (!form.consigneeBRN && !form.notifyPartyBRN) return true;

  // 둘 중 하나라도 값이 있고, 그 값이 10자리가 아니면 유효성 검사 실패
  if (form.consigneeBRN && form.consigneeBRN.length !== 10) return false;
  if (form.notifyPartyBRN && form.notifyPartyBRN.length !== 10) return false;

  return true;
};

const validateContainerSizeType = (form: CargoManifestInfoProps) => {
  if (!form.containers?.length) return true;

  return form.containers?.every((container) => {
    return container.containerType?.length === 4;
  });
};

function checkContainSpecialChars(input: string | null): boolean {
  if (!input) return false;

  const specialCharPattern = /[~'@#`\$|^{}_!\[\]\\]/g;
  return specialCharPattern.test(input);
}

const validateForm = (
  form: CargoManifestInfoProps,
  handleSnackbarOpen: (message: string, color?: AlertColor) => void,
  isImport: boolean
) => {
  if (form.carrierCode?.length !== 4) {
    handleSnackbarOpen("선사 코드를 확인해주세요. (4자리)", "warning");
    return false;
  }

  if (form.MRN?.length !== 11) {
    handleSnackbarOpen("MRN을 확인해주세요. (11자리)", "warning");
    return false;
  }

  if (form.packageSign?.length !== 2) {
    handleSnackbarOpen("포장부호 확인해주세요. (2자리)", "warning");
    return false;
  }

  if (!validateContainerSizeType(form)) {
    handleSnackbarOpen(
      "컨테이너의 Size/Type을 확인해주세요. (4자리)",
      "warning"
    );
    return false;
  }

  if (!validateClassification(form)) {
    handleSnackbarOpen("화물구분을 확인해주세요. (I, T, E, R)", "warning");
    return false;
  }

  if (!validatePackageCount(form)) {
    handleSnackbarOpen("총 포장개수를 확인해주세요.", "warning");
    return false;
  }

  if (!validateBRN(form)) {
    handleSnackbarOpen(
      "사업자번호가 10자리가 맞는지 확인해주세요. (- 생략)",
      "warning"
    );
    return false;
  }

  if (hasMoreThanThreeDecimalPlaces(form.totalWeight)) {
    handleSnackbarOpen(
      "총 중량은 소수점 3자리까지 입력 가능합니다.",
      "warning"
    );
    return false;
  }

  if (hasMoreThanThreeDecimalPlaces(form.totalCBM)) {
    handleSnackbarOpen(
      "총 용적은 소수점 3자리까지 입력 가능합니다.",
      "warning"
    );
    return false;
  }

  if (checkContainSpecialChars(form.shipperAddress)) {
    handleSnackbarOpen(
      "송하인 주소에 [~'@#$|^{}_!`]를 입력할 수 없습니다.",
      "warning"
    );
    return false;
  }

  if (checkContainSpecialChars(form.consigneeAddress)) {
    handleSnackbarOpen(
      "수하인 주소에 [~'@#$|^{}_!`]를 입력할 수 없습니다.",
      "warning"
    );
    return false;
  }

  if (checkContainSpecialChars(form.notifyPartyAddress)) {
    handleSnackbarOpen(
      "통지처 주소에 [~'@#$|^{}_!`]를 입력할 수 없습니다.",
      "warning"
    );
    return false;
  }

  if (isImport) {
    if (form.importation?.MSN?.length !== 4) {
      handleSnackbarOpen("MSN을 확인해주세요. (4자리)", "warning");
      return false;
    }

    if (
      form.importation?.spclCode?.length < 2 ||
      form.importation?.spclCode?.length > 4
    ) {
      handleSnackbarOpen("특수화물코드를 확인해주세요. (2~4자리)", "warning");
      return false;
    }

    if (form.importation?.assignCode?.length !== 1) {
      handleSnackbarOpen("배정구분코드를 확인해주세요. (1자리)", "warning");
      return false;
    }

    if (form.importation?.terminalCode?.length !== 8) {
      handleSnackbarOpen("배정장소를 확인해주세요. (8자리)", "warning");
      return false;
    }

    return true;
  }

  if (!isImport) {
    if (
      form.exportation?.partialShipment !== "Y" &&
      form.exportation?.partialShipment !== "N"
    ) {
      handleSnackbarOpen("분할선적여부를 확인해주세요. (Y, N)", "warning");
      return false;
    }

    return true;
  }

  return true;
};

export default validateForm;
