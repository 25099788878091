import { Controller, useFormContext } from "react-hook-form";
import { TextField } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

import { HBLInfo } from "@sellernote/_shared/src/types/forwarding/trello";

import TextFieldWithReactHookForm from "../../../../../../../../../../../components/TextFieldWithReactHookForm";

import Styled from "./index.styles";

function BLInfo() {
  const {
    control,
    formState: { errors },
  } = useFormContext<HBLInfo>();

  return (
    <Styled.blInfoSection>
      <Styled.blInfoColumn>
        <Styled.blInfoItem>
          <div className="border-top"></div>
          <div className="title">NUMBER OF ORIGINAL B/L(s)</div>

          <TextFieldWithReactHookForm
            name="circulation"
            control={control}
            required={true}
          />
        </Styled.blInfoItem>

        <Styled.blInfoItem>
          <div className="border-top"></div>
          <div className="title">PLACE OF B/L(s) ISSUE</div>
          <div className="content">SEOUL KOREA</div>
        </Styled.blInfoItem>
      </Styled.blInfoColumn>

      <Styled.blInfoColumn>
        <Styled.blInfoItem>
          <div className="border-top"></div>
          <div className="title">Payable (at) freight</div>
          <div className="content">SEOUL KOREA</div>
        </Styled.blInfoItem>

        <Styled.blInfoItem>
          <div className="border-top"></div>

          <div className="title">Dated</div>

          <Controller
            control={control}
            name="desiredAt"
            rules={{ required: true }}
            render={({ field: { ref, ...field } }) => {
              return (
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    {...field}
                    componentsProps={{
                      actionBar: { actions: ["clear"] },
                    }}
                    value={field.value}
                    inputFormat="MMM.dd.yyyy"
                    InputProps={{
                      style: {
                        fontSize: "9px",
                      },
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        onKeyDown={(event) => {
                          event.preventDefault();
                        }}
                        helperText={
                          errors.desiredAt?.type === "required" &&
                          "필수 입력 사항입니다."
                        }
                        error={errors.desiredAt?.type === "required"}
                        size="small"
                      />
                    )}
                  />
                </LocalizationProvider>
              );
            }}
          />
        </Styled.blInfoItem>
      </Styled.blInfoColumn>
    </Styled.blInfoSection>
  );
}

export default BLInfo;
