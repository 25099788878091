import { Box, Button, Typography } from "@mui/material";

import TRELLO_BID_QUERY from "@sellernote/_shared/src/queries/forwarding/TRELLO_BID_QUERY";
import { TrelloBidManagement } from "@sellernote/_shared/src/types/forwarding/trello";

import useGeneratorModal from "./_hooks/useGeneratorModal";
import GeneratorModal from "./GeneratorModal";

function HblGenerator({ management }: { management: TrelloBidManagement }) {
  const { data: HBLData, isFetching: isHBLDataFetching } =
    TRELLO_BID_QUERY.useGetHBLInfo({
      shipmentId: management.bidId,
    });

  const generatorButtonDisabled = (() => {
    // DirectMBL일 때는 disabled
    if (management.BLType === "DirectMBL") return true;

    return false;
  })();

  const { opensGeneratorModal, handleGeneratorModalToggle } =
    useGeneratorModal();

  // TextFiled 처럼 보이게 하기 위한 Box 스타일
  const commonBorderBoxStyle = {
    position: "relative" as const,
    border: "1px solid #ccc",
    borderRadius: "4px",
    padding: "8px 8px",
    backgroundColor: "#ffffff",
    minWidth: 150,
  };

  // TextFiled의 라벨처럼 보이게 하기 위한 Typography 스타일
  const commonLabelTypographyStyle = {
    position: "absolute" as const,
    top: 0,
    left: "8px",
    transform: "translateY(-50%)",
    backgroundColor: "#ffffff",
    padding: "0 4px",
  };

  return (
    <Box mb={1}>
      {HBLData?.blType && HBLData?.blNumber ? (
        <Box display={"flex"} gap={2} alignItems={"center"} mt={2}>
          <Typography variant={"body1"}>HBL: </Typography>

          <Box
            sx={{
              ...commonBorderBoxStyle,
            }}
          >
            <Typography
              variant="caption"
              sx={{
                ...commonLabelTypographyStyle,
              }}
            >
              BL TYPE
            </Typography>

            <Typography variant="body1">{HBLData?.blType}</Typography>
          </Box>

          <Box
            sx={{
              ...commonBorderBoxStyle,
            }}
          >
            <Typography
              variant="caption"
              sx={{
                ...commonLabelTypographyStyle,
              }}
            >
              BL NO.
            </Typography>

            <Typography variant="body1">{HBLData?.blNumber}</Typography>
          </Box>

          <Button
            variant="contained"
            disabled={generatorButtonDisabled}
            onClick={handleGeneratorModalToggle}
            size="small"
          >
            HBL 수정
          </Button>
        </Box>
      ) : (
        <Box display={"flex"} alignItems={"center"} gap={1}>
          <Typography variant={"body1"}>HBL: </Typography>

          <Button
            variant="contained"
            disabled={generatorButtonDisabled}
            onClick={handleGeneratorModalToggle}
          >
            HBL 생성
          </Button>
        </Box>
      )}

      {generatorButtonDisabled && (
        <Typography variant="body2" color="error">
          *MBL Direct인 경우 HBL 생성 필드가 활성화되지 않습니다.
        </Typography>
      )}

      {HBLData && (
        <GeneratorModal
          opensGeneratorModal={opensGeneratorModal}
          onGeneratorModalToggle={handleGeneratorModalToggle}
          shipmentId={management.bidId}
          HBLData={HBLData}
        />
      )}
    </Box>
  );
}

export default HblGenerator;
