import { Box, Divider } from "@mui/material";

import { GET_CARGO_MANIFEST_RES as CargoManifestInfoProps } from "@sellernote/_shared/src/api-interfaces/shipda-api/trello";

import { ContainerFormChangeProps } from "../../types";

import AddressForm from "./AddressForm";
import ContainerInfoForm from "./ContainerInfoForm";
import FirstCategoryForm from "./FirstCategoryForm";
import SecondCategoryForm from "./SecondCategoryForm";

export default function FormList({
  form,
  onFormChange,
  onContainerFormChange,
  onETAChange,
  isEditMode,
  isImport,
}: {
  form: CargoManifestInfoProps;
  onFormChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onContainerFormChange: ({
    index,
    key,
    value,
  }: ContainerFormChangeProps) => void;
  onETAChange: (value: string | null) => void;
  isEditMode: boolean;
  isImport: boolean;
}) {
  return (
    <>
      <Box display={"flex"} gap={3}>
        <FirstCategoryForm
          form={form}
          onFormChange={onFormChange}
          isEditMode={isEditMode}
          isImport={isImport}
        />

        <Divider orientation="vertical" flexItem />

        <Box
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"space-evenly"}
        >
          <SecondCategoryForm
            form={form}
            onFormChange={onFormChange}
            onETAChange={onETAChange}
            isEditMode={isEditMode}
          />

          <Divider sx={{ mt: 2, mb: 2 }} />

          <AddressForm
            form={form}
            onFormChange={onFormChange}
            isEditMode={isEditMode}
          />
        </Box>
      </Box>

      {Boolean(form.containers?.length) && (
        <>
          <Divider />

          <ContainerInfoForm
            form={form}
            onContainerFormChange={onContainerFormChange}
            isEditMode={isEditMode}
          />
        </>
      )}
    </>
  );
}
