import { useFormContext } from "react-hook-form";

import { HBLInfo } from "@sellernote/_shared/src/types/forwarding/trello";

import TextFieldWithReactHookForm from "../../../../../../../../../../../components/TextFieldWithReactHookForm";

import Styled from "./index.styles";

function RouteInfo() {
  const { control, watch } = useFormContext<HBLInfo>();

  const { partnerName, shipName, voyageNumber } = watch();

  return (
    <Styled.routeInfoSection>
      <Styled.routeInfoColumn>
        <Styled.routeInfoRow>
          <Styled.routeInfoItemColumn>
            <div className="border-top"></div>

            <div className="title">Pre-carriage by</div>

            <TextFieldWithReactHookForm
              name="transportMode"
              control={control}
              fullWidth
              required={true}
            />
          </Styled.routeInfoItemColumn>

          <Styled.routeInfoItemColumn>
            <div className="border-top"></div>

            <div className="title">Place of Receipt</div>

            <TextFieldWithReactHookForm
              name="origin"
              control={control}
              fullWidth
              required={true}
            />
          </Styled.routeInfoItemColumn>
        </Styled.routeInfoRow>

        <Styled.routeInfoRow>
          <Styled.routeInfoItemColumn>
            <div className="border-top"></div>
            <div className="title">VESSEL / VOYAGE NO.</div>

            <div className="content">{`${shipName} ${voyageNumber}`}</div>
          </Styled.routeInfoItemColumn>

          <Styled.routeInfoItemColumn>
            <div className="border-top"></div>
            <div className="title">PORT OF LOADING</div>

            <TextFieldWithReactHookForm
              name="pol"
              control={control}
              fullWidth
              required={true}
            />
          </Styled.routeInfoItemColumn>
        </Styled.routeInfoRow>

        <Styled.routeInfoRow>
          <Styled.routeInfoItemColumn>
            <div className="border-top"></div>
            <div className="title">PORT OF DISCHARGE</div>

            <TextFieldWithReactHookForm
              name="pod"
              control={control}
              fullWidth
              required={true}
            />
          </Styled.routeInfoItemColumn>

          <Styled.routeInfoItemColumn>
            <div className="border-top"></div>
            <div className="title">PLACE OF DELIVERY</div>

            <TextFieldWithReactHookForm
              name="dest"
              control={control}
              fullWidth
              required={true}
            />
          </Styled.routeInfoItemColumn>
        </Styled.routeInfoRow>
      </Styled.routeInfoColumn>

      <Styled.goodsApplySection>
        <Styled.goodsApplyTitle>
          <div className="border-top"></div>
          <div className="title">FOR DELIVERY OF GOODS APPLY TO</div>
        </Styled.goodsApplyTitle>

        <Styled.goodsApplyContent>
          {partnerName}
          <br />
          <TextFieldWithReactHookForm
            name="partnerAddress"
            control={control}
            fullWidth={true}
            multiline={true}
            placeholder={"partnerAddress"}
            rows={3}
            required={true}
          />
          <br />
        </Styled.goodsApplyContent>
      </Styled.goodsApplySection>
    </Styled.routeInfoSection>
  );
}

export default RouteInfo;
